

















































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {inject} from '@/container';
import {UseConcordiaExperiment1SlopeIntercept} from '@/tasks/composables/UseConcordiaExperiment1SlopeIntercept';

export type PressureVTemp = {
  pressure: number | null;
  temperature: number | null;
};

export default defineComponent({
  name: 'ConcordiaChem206Experiment1Part2',
  components: {StembleLatex, AssistedGradingWritten, ApexChart: VueApexCharts},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const {inputs, colDefs, equation, apexSeries, apexOptions, addRun, removeRun} = inject(
      UseConcordiaExperiment1SlopeIntercept,
      {pressure: 'Pressure (atm)', temperature: 'Temperature (C)'},
      'pressure',
      'temperature',
      'pressureTemp',
      props.isMarking,
      'P',
      'T',
      0.5,
      1.5,
      0,
      80
    );

    return {
      pressureTempColDefs: colDefs,
      equation,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
});
